import mapboxgl from '!mapbox-gl';

const zoomMapToMarkers = (map, markers) => {
  const bounds = new mapboxgl.LngLatBounds();
  markers.forEach((marker) => {
    bounds.extend([ marker.lng, marker.lat ])
  })
// LVZ 10/10/22 natural earth map remove fitbounds
//   map.fitBounds(bounds, { padding: 50, maxZoom: 15, duration: 0 });
};

// This function was inspired on this tutorial: https://docs.mapbox.com/mapbox-gl-js/example/cluster/
const addClustersToMap = (map, markers) => {
  // create an array of GeoJson features from the markers
  const features = markers.map(marker => (
    {
      "type": "Feature",
      "properties": {
        "info_window": marker.info_window
      },
      "geometry": {
        "type": "Point",
        "coordinates": [marker.lng, marker.lat, 0.0]
      }
    }
  ))

  map.on('load', () => {

    // Add geolocate control to the map.
map.addControl(
  new mapboxgl.GeolocateControl({
  positionOptions: {
  enableHighAccuracy: true
  },
  // When active the map will receive updates to the device's location as it changes.
  trackUserLocation: true,
  // Draw an arrow next to the location dot to indicate which direction the device is heading.
  showUserHeading: true
  })
  );

    const geojsonData = {
      "type": "FeatureCollection",
      "features": features
    };

    // Add a new source from our GeoJSON data and
    // set the 'cluster' option to true. GL-JS will
    // add the point_count property to your source data.
    map.addSource('my-markers', {
      type: 'geojson',
      // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
      // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
      data: geojsonData,
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    });

    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'my-markers',
      filter: ['has', 'point_count'],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 10
        //   * Yellow, 30px circles when point count is between 10 and 20
        //   * Green, 40px circles when point count is greater than 20 or equal to 40
        //   * Purple, 50px circles when point count is greater than or equal to 40
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          15,
          '#f1f075',
          30,
          '#30ed46',
          50,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          10,
          30,
          20,
          40,
          40,
          50
        ]
      }
    });

    // Add count inside cluster
    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'my-markers',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    });

    // Add dots for cluster (clickable with popups)
    map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'my-markers',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#039FE1',
        'circle-radius': 8,
        'circle-stroke-width': 3,
        'circle-stroke-color': '#fff'
      }
    });

    // inspect a cluster on click (open the popup)
    map.on('click', 'clusters', (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      const clusterId = features[0].properties.cluster_id;
      map.getSource('my-markers').getClusterExpansionZoom(
        clusterId,
        (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        }
      );
    });

    // When a click event occurs on a feature in
    // the unclustered-point layer, open a popup at
    // the location of the feature, with
    // description HTML from its properties.
    map.on('click', 'unclustered-point', (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice();
      const info_window = e.features[0].properties.info_window;

      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(info_window)
        .addTo(map);
    });

    // change mouse to 'pointer' when hovering on clusters
    map.on('mouseenter', 'clusters', () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    // change mouse back to normal one when hovering out of clusters
    map.on('mouseleave', 'clusters', () => {
      map.getCanvas().style.cursor = '';
    });
  });
}

const initMapbox = () => {
  const mapElement = document.getElementById('map');

  if (mapElement) {
    mapboxgl.accessToken = mapElement.dataset.mapboxApiKey;
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/satellite-streets-v12',
      // LVZ 10/10/22 natural earth map add these 3 lines
      center: [0, 0],
      zoom: 1.0,
      projection: 'globe' // starting projection
    });

    const markers = JSON.parse(mapElement.dataset.markers);
    zoomMapToMarkers(map, markers)
    addClustersToMap(map, markers);
  }
};

export { initMapbox };
